import {LitElement, html, css} from 'lit';
import '@dile/ui/components/select/select';
import { months } from '../../helpers/dates-helper';

class TimeSelect  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
        margin-bottom: 15px;
      }
      section {
        background-color: var(--time-select-background-color, #f3f3f3);
        padding: var(--time-select-padding, 15px);
        border-radius: 15px;
      }
      .fields {
        display: flex;
      }
      .year {
        flex-grow: 1;
        margin-right: 10px;
      }
      .month {
        flex-grow: 2;
      }
      .label {
        display: block;
        margin-bottom: 5px;
        padding-left: 5px;
      }
      .yearsselect, .monthselect {
        --dile-select-width: 100%;
      }
    `;
  }

  static get properties() {
    return {
      label: { type: String },
      year: { type: Number },
      month: { type: Number },
      startYear: { type: Number },
      yearsArray: { type: Array },
      monthsArray: { type: Array },
    };
  }

  constructor() {
    super();
    this.startYear = 2019;
    this.yearsArray = [];
    this.monthsArray = months;
    this.endYear = this.calculateEndYear(); 
    
  }

  calculateEndYear() {
    let now = new Date();
    let currentYear = now.getFullYear();
    let currentMonth = now.getMonth();
    if(currentMonth >= 8) {
      return currentYear + 1;
    }
    return currentYear;
  }
  firstUpdated() {
    let newArrayYears = [];
    //console.log('aki', this.startYear, this.endYear);
    for (let i = this.startYear; i <= this.endYear; i++) {
      newArrayYears.push(i);
    }
    this.yearsArray = newArrayYears;
  }

  render() {
    return html`
      <section>
        ${this.label
          ? html`<span class="label">${this.label}</span>`
          : ''
        }
        <div class="fields">
          <div class="year">
            <dile-select id="select1" value="${this.year}" class="yearsselect" label="Año" @element-changed=${this.selectYear}>
              <select slot="select">
                ${this.yearsArray.map((year) => html`<option value="${year}">${year}</option>`)}
              </select>
            </dile-select>
          </div>
          <div class="month">
            <dile-select class="monthselect" value="${this.month}" label="Mes" @element-changed="${this.selectMonth}">
              <select slot="select">
                ${this.monthsArray.map( (month, index) => html`
                  ${ month != 'Julio' && month != 'Agosto'
                    ? html`<option value="${index + 1}">${month}</option>`
                    : ''
                  }
                `)}
              </select>
            </dile-select>
          </div>
        </div>
      </section>
    `;
  }

  selectMonth(e) {
    // console.log('selectMonth', e.detail.value, this.month);
    if(e.detail.value != this.month) {
      this.dispatchEvent(new CustomEvent('month-changed', {
        bubbles: true,
        composed: true,
        detail: {
          value: e.detail.value,
        }
      }));
    }
    this.month = e.detail.value;
  } 

  selectYear(e) {
    //console.log('selectMonth', e.detail.value, this.year);
    if(e.detail.value != this.year) {
      this.dispatchEvent(new CustomEvent('year-changed', {
        bubbles: true,
        composed: true,
        detail: {
          value: e.detail.value,
        }
      }));
    }
    this.year = e.detail.value;
  } 
}

customElements.define('time-select', TimeSelect);